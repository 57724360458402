body {
  background-color: #ffefd5;
  padding: 40px 20px 20px;
}
text {
  font-size: 20px;
  pointer-events: none;
}
#chart {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#question h1 {
  font-size: 60px;
}
.text-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#title h1 {
  font-size: 30px;
}
#question h1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#image {
  justify-content: center;
  display: flex;
  height: 0;
  visibility: hidden;
}
